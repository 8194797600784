<template>
  <div>
    <!-- Loader -->
    <full-page-loader
      v-if="loading"
      text="Ladataan laskusivua..."
      class="full-page-loader"
    ></full-page-loader>

    <div v-if="!loading">
      <!-- Contract Info -->
      <!-- <contract-info :isRefundForm="isRefundForm" :invoice="invoice" :contract="contract" /> -->

      <v-form ref="form">
        <v-card class="mt-2">
          <v-card-title>Laskutuksessa käytettävä tili</v-card-title>
          <v-container>
            <v-row dense>
              <v-col v-if="serviceAccounts.length > 1" cols="12" sm="6" md="4" lg="3" xl="2">
                <v-select
                  v-model="serviceAccountId"
                  :items="serviceAccounts"
                  item-value="cid"
                  item-text="name"
                  :rules="validations.required"
                  label="Valitse tili"
                  outlined
                  dense
                  hide-details
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card>

        <v-card class="mt-2">
          <v-card-title>Laskun tiedot</v-card-title>
          <v-container>
            <v-row dense>
              <v-col cols="12">
                <h3>Lasku</h3>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                <v-menu
                  ref="billDateMenu"
                  v-model="billDateMenu"
                  :close-on-content-click="false"
                  min-width="290"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      :value="formatDate(invoice.billDate)"
                      v-bind="attrs"
                      :rules="validations.required"
                      outlined
                      dense
                      v-on="on"
                      append-icon="mdi-calendar"
                      label="Laskun pvm."
                      v-prevent-manual-input
                      :readonly="true"
                      hide-details
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="invoice.billDate"
                    :allowed-dates="allowedBillDates"
                    first-day-of-week="1"
                    @input="billDateMenu = false"
                    @change="updateDates('billDate')"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                <v-menu
                  ref="accountDateMenu"
                  v-model="accountDateMenu"
                  :close-on-content-click="false"
                  min-width="290"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      :disabled="
                        !showBasedOnService({ ropoCapital: true, talenom: false }) || isRefundForm
                      "
                      :value="formatDate(invoice.accountDate)"
                      v-bind="attrs"
                      :rules="validations.required"
                      outlined
                      dense
                      v-on="on"
                      append-icon="mdi-calendar"
                      label="Laskun kirjauspvm."
                      v-prevent-manual-input
                      :readonly="true"
                      hide-details
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="invoice.accountDate"
                    :allowed-dates="allowedAccountDates"
                    first-day-of-week="1"
                    @input="accountDateMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col v-if="!isRefundForm" cols="12" sm="6" md="3" lg="2" xl="1">
                <v-text-field
                  v-model.number="invoice.firstPaymentTerm"
                  v-only-numbers
                  v-prevent-paste
                  outlined
                  dense
                  :rules="validations.required.concat(validations.positiveNumber)"
                  label="Maksuehto"
                  suffix="pv"
                  @input="updateDates('paymentTerms')"
                  hide-details
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                <v-menu
                  ref="firstDueDateMenu"
                  v-model="firstDueDateMenu"
                  :close-on-content-click="false"
                  min-width="290"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      :value="formatDate(invoice.dueDate)"
                      v-bind="attrs"
                      :rules="validations.required"
                      outlined
                      dense
                      v-on="on"
                      append-icon="mdi-calendar"
                      label="Laskun eräpäivä"
                      v-prevent-manual-input
                      :readonly="true"
                      hide-details
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="invoice.dueDate"
                    :allowed-dates="allowedDueDates"
                    first-day-of-week="1"
                    @input="firstDueDateMenu = false"
                    @change="updateDates('dueDate')"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                <v-select
                  v-model="invoice.sendType"
                  outlined
                  dense
                  :items="sendTypes"
                  item-value="val"
                  item-text="text"
                  :rules="validations.required"
                  label="Laskun lähetystapa"
                  hide-details
                  @change="handleSendTypeChange"
                ></v-select>
              </v-col>

              <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                <v-text-field
                  v-model="invoice.yourCode"
                  outlined
                  dense
                  label="Ostajan viite"
                  hide-details
                ></v-text-field>
                <small>Näkyy laskulla "Viitteenne"</small>
              </v-col>
            </v-row>
          </v-container>
        </v-card>

        <v-card class="mt-2">
          <v-card-title>Laskutustiedot</v-card-title>
          <v-container>
            <!-- Billing address -->
            <v-row dense>
              <v-col cols="12">
                <h3>Laskun saajan tiedot</h3>
              </v-col></v-row
            >

            <p
              style="font-size: 13px"
              v-if="!isRefundForm && showBasedOnService({ ropoCapital: true, talenom: false })"
            >
              Laskun saajan viitenumero, asiakasnumero ja y-tunnus / hetu siirtyvät laskulle
            </p>

            <v-row dense>
              <v-col v-if="!isRefundForm" cols="12" sm="6" md="4" lg="3" xl="2">
                <v-select
                  v-model="principalTenant"
                  outlined
                  dense
                  :item-text="invoiceHelpers.getPrincipalTenantNameInfo"
                  item-value="tenantId._id"
                  :items="invoiceReceivers"
                  label="Laskun saaja"
                  return-object
                  hide-details
                  :rules="validations.required"
                  @change="
                    updateReceiverInfo($event);
                    updateInvoiceLanguage();
                    showPopup('Huomaa muuttunut laskun viitenumero', 'info');
                  "
                ></v-select>
              </v-col>

              <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                <v-text-field
                  v-model="invoice.tenant.name"
                  outlined
                  dense
                  :rules="
                    validations.required.concat(maxCharacters({ talenom: 45, ropoCapital: 50 }))
                  "
                  label="Laskun saajan nimi"
                ></v-text-field>
              </v-col>

              <v-col v-if="invoice.sendType == 'email'" cols="12" sm="6" md="4" lg="3" xl="2">
                <v-text-field
                  v-model="invoice.tenant.email"
                  label="Laskun saajan email"
                  :rules="validations.required.concat(validations.email)"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>

              <v-col
                v-if="
                  invoice.sendType == 'email' &&
                  showBasedOnService({ ropoCapital: true, talenom: false })
                "
                cols="12"
                sm="6"
                md="4"
                lg="3"
                xl="2"
              >
                <v-text-field
                  v-model="invoice.tenant.email2"
                  label="Laskun saajan email 2"
                  :rules="validations.email2"
                  outlined
                  dense
                  hide-details
                >
                </v-text-field>
              </v-col>
            </v-row>

            <!-- Evoice -->
            <div v-if="invoice.sendType == `evoice`">
              <v-row dense>
                <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                  <v-btn
                    class="mt-1 mb-1"
                    small
                    color="primary"
                    @click="getTenantEvoiceInformation(false)"
                    >Hae verkkolaskutiedot</v-btn
                  >
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                  <v-text-field
                    v-model="invoice.evoice.evoiceAddress"
                    label="Verkkolaskuosoite"
                    :rules="validations.required"
                    outlined
                    dense
                    :disabled="contactHasServiceId(principalTenant)"
                    :persistent-hint="true"
                    :hint="
                      contactHasServiceId(principalTenant)
                        ? 'Vaihda laskun verkkolaskuosoite muokkaamalla vuokralaista'
                        : 'Vuokralaisen verkkolaskuosoitteeksi tallennetaan valittu osoite'
                    "
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                  <v-text-field
                    v-model="invoice.evoice.evoiceId"
                    label="Operaattoritunnus"
                    placeholder="esim. BAWCFI22"
                    :rules="validations.required"
                    outlined
                    dense
                    :disabled="contactHasServiceId(principalTenant)"
                    :persistent-hint="true"
                    :hint="
                      contactHasServiceId(principalTenant)
                        ? 'Vaihda laskun operaattoritunnus muokkaamalla vuokralaista'
                        : 'Vuokralaisen operaattoritunnukseksi tallennetaan valittu operaattoritunnus'
                    "
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                  <v-text-field
                    v-model="invoice.evoice.evoiceOperator"
                    label="Välittäjä"
                    placeholder="Esim. Basware Oyj"
                    :rules="[isRequiredByInvoiceService({ ropoCapital: false, talenom: true })]"
                    outlined
                    dense
                    :disabled="contactHasServiceId(principalTenant)"
                    :persistent-hint="true"
                    :hint="
                      contactHasServiceId(principalTenant)
                        ? 'Vaihda välittäjä muokkaamalla vuokralaista'
                        : invoice.evoice.evoiceOperator
                        ? 'Vuokralaisen verkkolaskun välittäjäksi tallennetaan valittu välittäjä'
                        : ''
                    "
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>

            <!-- Billing address -->
            <v-row class="mt-1" dense>
              <v-col cols="12">
                <h3>Laskutusosoite</h3>
              </v-col></v-row
            >

            <v-row dense>
              <v-col cols="6" md="6" lg="3" xl="2">
                <v-radio-group
                  v-model="invoice.sendToApartmentAddress"
                  :mandatory="true"
                  @change="setSendingAddress"
                  style="margin-top: 0px"
                  hide-details
                  label="Laskun lähetys"
                >
                  <v-radio
                    label="Lasku lähetetään vuokrakohteen osoitetiedoilla"
                    :value="true"
                  ></v-radio>
                  <v-radio label="Lasku lähetetään muilla osoitetiedoilla" :value="false"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col v-if="!invoice.sendToApartmentAddress" cols="12" md="6" lg="4" xl="3">
                <v-btn class="mt-1" small color="primary" @click="setSendingAddress"
                  >Hae vuokralaisen osoite</v-btn
                >
              </v-col>
            </v-row>

            <v-row v-if="!invoice.sendToApartmentAddress" dense
              ><v-col cols="12" sm="6" md="4" lg="3">
                <v-checkbox
                  v-model="invoice.sendingAddress.addExtraLine"
                  style="margin-top: 0px"
                  label="Lisää uusi osoiterivi (esim. Docuscan)"
                  hide-details
                  @change="invoice.sendingAddress.addressLine2 = null"
                ></v-checkbox> </v-col
            ></v-row>

            <v-row dense>
              <v-col v-if="invoice.sendingAddress.addExtraLine" cols="12" md="4" lg="3" xl="2">
                <v-text-field
                  v-model="invoice.sendingAddress.addressLine2"
                  label="Osoiterivi 1"
                  placeholder="Esim. OVT 12345678"
                  outlined
                  dense
                  hide-details
                  :rules="validations.required"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4" lg="3" xl="2">
                <v-text-field
                  v-model="invoice.sendingAddress.address"
                  :label="invoice.sendingAddress.addExtraLine ? 'Osoiterivi 2' : 'Osoite'"
                  placeholder="Esim. Testikatu 5 A 7"
                  outlined
                  dense
                  hide-details
                  :rules="validations.required"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4" lg="3" xl="2">
                <v-text-field
                  v-model="invoice.sendingAddress.zipCode"
                  label="Postinumero"
                  outlined
                  dense
                  hide-details
                  :rules="validations.required"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4" lg="3" xl="2">
                <v-text-field
                  v-model="invoice.sendingAddress.city"
                  label="Kaupunki"
                  outlined
                  dense
                  hide-details
                  :rules="validations.required"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4" lg="3" xl="2">
                <v-autocomplete
                  v-model="invoice.sendingAddress.country"
                  :items="getCountries()"
                  item-text="text"
                  item-value="code"
                  clearable
                  outlined
                  label="Maa"
                  dense
                  :rules="[
                    isRequiredByInvoiceService({ ropoCapital: false, talenom: true }),
                    isInvoiceAddressCountryAvailable,
                  ]"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card>

        <!-- Products -->

        <v-card class="mt-2">
          <v-card-title>Tuotteet</v-card-title>
          <v-container>
            <v-row v-if="isRefundForm" dense class="mb-1">
              <v-col md="4">
                <small class="error--text"
                  >Tuotemäärä merkataan negatiivisena, tuotteen hinta positiivisena</small
                >
              </v-col>
            </v-row>

            <div v-for="(product, index) in invoice.products" :key="index" dense>
              <v-row dense class="mb-1">
                <v-col cols="6" md="3" lg="2">
                  <label>Valitse tuote</label>
                  <v-autocomplete
                    :value="product.productId"
                    :items="products"
                    item-text="desc"
                    item-value="_id"
                    dense
                    outlined
                    small-chips
                    return-object
                    @change="setProduct($event, { ...product }, index)"
                    hide-details
                    :rules="validations.required"
                  ></v-autocomplete>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="6" md="2" lg="2">
                  <v-text-field
                    v-model.number="product.desc"
                    outlined
                    dense
                    :rules="validations.required"
                    label="Kuvaus"
                    hide-details
                  />
                </v-col>

                <v-col cols="6" md="2" lg="2">
                  <v-text-field
                    v-model.number="product.amount"
                    outlined
                    dense
                    step="0.01"
                    type="number"
                    :rules="validations.required.concat(validations.positiveNumber)"
                    label="Hinta alv 0%"
                    suffix="€"
                    hide-details
                  />
                </v-col>

                <v-col cols="6" md="2" lg="1">
                  <v-text-field
                    v-model.number="product.count"
                    outlined
                    dense
                    v-only-numbers
                    v-prevent-paste
                    :rules="validateInvoiceProductCount(isRefundForm)"
                    label="Määrä"
                    hide-details
                  ></v-text-field>
                </v-col>

                <v-col cols="6" md="2" lg="2" xl="1">
                  <v-select
                    v-model="product.itemtype"
                    outlined
                    dense
                    :items="productItemTypes"
                    label="Tuotetyyppi"
                    return-object
                    hide-details
                    :rules="validations.required"
                  ></v-select>
                </v-col>

                <v-col cols="6" md="3" lg="2">
                  <div class="details-container">
                    <v-select
                      v-model.number="product.taxpr"
                      outlined
                      dense
                      :items="vatTypes"
                      item-value="val"
                      item-text="text"
                      label="Alv-kanta"
                      hide-details
                      :rules="validations.zeroOrGreater"
                    ></v-select>

                    <v-icon class="ml-1" color="error" small @click="deleteProduct(index)"
                      >mdi-delete</v-icon
                    >
                  </div>
                </v-col>
              </v-row>

              <div v-if="product.accountingId" style="margin-top: 7px">
                <p style="font-size: 13px; margin-bottom: 0px !important">
                  Tiliöintikoodi: {{ product.accountingId }}
                </p>
                <v-divider class="mb-1 mt-2"></v-divider>
              </div>
              <v-divider v-else class="mt-2 mb-1"></v-divider>
            </div>

            <v-row v-if="!isRefundForm" :class="{ 'mt-2': invoice.products.length > 0 }" dense>
              <v-col cols="12">
                <v-btn color="primary" class="mr-2 mb-1" @click="addProduct()"
                  >Lisää tuoterivi</v-btn
                >
              </v-col>
            </v-row>

            <v-row v-if="isCompany && checkVatFreeProducts() && !isRefundForm" class="mt-1" dense>
              <v-col cols="12" sm="6" md="4" lg="3">
                <label style="margin: 0">Alv 0 % selite</label>
                <v-text-field
                  v-model="invoice.vatFreeText"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- VATS -->
            <vat-component
              v-if="invoice.products.length > 0"
              class="mt-2"
              :products="invoice.products"
            ></vat-component>
          </v-container>
        </v-card>

        <!-- Other Data -->
        <v-card class="mt-2">
          <v-card-title>Muut tiedot</v-card-title>
          <v-container>
            <v-row dense
              ><v-col cols="12"><h3>Laskun kieli</h3></v-col>
            </v-row>

            <v-row dense>
              <v-col cols="6" md="4" lg="3" xl="2">
                <v-select
                  v-model="invoice.language"
                  item-text="text"
                  item-value="val"
                  :items="getServiceInvoiceLanguages()"
                  outlined
                  dense
                  label="Laskun kieli"
                  hide-details
                  :rules="validations.required"
                  :disabled="contactHasServiceId(principalTenant)"
                ></v-select>
              </v-col>
            </v-row>

            <v-divider class="mt-2 mb-1"></v-divider>

            <v-row dense>
              <v-col cols="12">
                <h3 v-if="showBasedOnService({ talenom: false, ropoCapital: true })">
                  Vapaa teksti ja liite
                </h3>
                <h3 v-if="showBasedOnService({ talenom: true, ropoCapital: false })">
                  Vapaa teksti
                </h3>
              </v-col></v-row
            >

            <v-row dense>
              <v-col cols="12" sm="6" md="5" lg="4" xl="3">
                <v-textarea
                  v-model="invoice.freeText"
                  outlined
                  hide-details
                  class="mt-2"
                  label="Laskun vapaa teksti"
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row
              dense
              v-if="showBasedOnService({ talenom: false, ropoCapital: true })"
              class="mt-1"
            >
              <v-col sm="10" md="8" lg="6" xl="5">
                <div>
                  <v-checkbox
                    style="margin-top: 0; padding-top: 0"
                    v-model="invoice.addAttachment"
                    label="Lisää pdf-liite"
                    @change="handleAddAttachmentChange"
                    hide-details
                  ></v-checkbox>

                  <small v-if="invoice.addAttachment" class="error--text"
                    >A4-lisäsivun hinta on 0,07 €/kpl</small
                  >
                </div>

                <div v-if="invoice.addAttachment">
                  <v-radio-group
                    v-model="invoice.userCreatedAttachment"
                    :mandatory="true"
                    style="margin-top: 5px"
                    @change="handleUserCreatedAttachmentChange"
                  >
                    <v-radio label="Luo oma pdf-tiedosto" :value="true"></v-radio>
                    <v-radio label="Liitä valmis pdf-tiedosto" :value="false"></v-radio>
                  </v-radio-group>

                  <!-- Editor -->
                  <QuillEditor v-if="showQuillEditor" ref="QuillEditor"></QuillEditor>
                  <!-- Add file -->
                  <upload-pdf v-if="showUploadPdf" ref="uploadPdf"></upload-pdf>
                </div>
              </v-col>
            </v-row>

            <v-divider class="mt-2 mb-1"></v-divider>

            <v-row dense>
              <v-col v-if="!isRefundForm" cols="12" sm="6" md="4" lg="3">
                <!-- PERSONALREFNUM -->
                <personal-ref-num-component
                  :principalTenant="principalTenant"
                  :serviceAccountId="serviceAccountId"
                  :selectAccountFirst="true"
                ></personal-ref-num-component>
              </v-col>

              <v-col cols="12" sm="6" md="4" lg="3">
                <!-- COSTCENTRE  -->
                <CostCentre :deposit="currentDeposit" :invoice="invoice"></CostCentre>
              </v-col>

              <v-col v-if="!isRefundForm" cols="12" sm="6" md="4" lg="3">
                <h3 class="mb-1">Maksumuistutukset</h3>

                <small
                  >Jos kytket automaattiset maksumuistutukset pois päältä, mitkään muistutus- tai
                  perintäpalvelut eivät ole ensimmäisen eikä toistuvaislaskujen osalta käytössäsi.
                </small>
                <v-checkbox
                  v-model="invoice.autoReminder"
                  label="Automaattiset maksumuistutukset"
                  class="mt-2"
                  hide-details
                ></v-checkbox>
              </v-col>
            </v-row>

            <v-row class="mt-2" dense>
              <v-col cols="12">
                <v-btn color="info" :loading="saving" @click="submit">{{ saveBtnText }}</v-btn>
              </v-col></v-row
            >
          </v-container>
        </v-card>
      </v-form>
    </div>
  </div>
</template>

<script>
import CostCentre from "./CostCentre.vue";
import PersonalRefNumComponent from "./PersonalRefNumComponent.vue";
import FullPageLoader from "@/components/FullPageLoader";
import mixins from "../../mixins/mixins";
import invoiceMixins from "../../mixins/invoiceMixins";
import moment from "moment";
import { mapState, mapActions, mapMutations } from "vuex";
import _ from "lodash";
import validations from "@/validations";
import globalValues from "../../configs/globalValues";
import invoiceHelpers from "@/utils/invoiceHelpers";
import VatComponent from "@/components/Invoice/VatComponent";
import QuillEditor from "@/components/QuillEditor.vue";
import UploadPdf from "@/components/UploadPdf.vue";

export default {
  mixins: [mixins, invoiceMixins],

  components: {
    CostCentre,
    PersonalRefNumComponent,
    FullPageLoader,
    VatComponent,
    QuillEditor,
    UploadPdf,
  },

  props: {
    inv: {
      type: Object,
      default: function () {
        return {};
      },
    },
    refundedInv: {
      type: Object,
      default: function () {
        return {};
      },
    },
    saveBtnText: { type: String, default: "" },
  },

  data() {
    return {
      firstDueDateMenu: false,
      billDateMenu: false,
      accountDateMenu: false,
      menuOutgoing: false,
      firstDueDate: null,
      isCalculated: false,
      otherDueDatesDefault: null,
      principalTenant: { tenantId: { personalRefNumInUse: false } },
      showQuillEditor: true,
      showUploadPdf: false,
      serviceAccountId: null,
      invoice: {
        yourCode: "",
        language: "fin",
        tenant: { email: "", email2: "", name: "" },
        refundInvoiceIds: [],
        autoReminder: true,
        isRecurrent: false,
        billDateAsDueDate: true,
        shareAmountPerDays: false,
        otherDueDates: null,
        billDate: null,
        paymentTerm: 14,
        firstPaymentTerm: 14,
        invoiceInterval: 1,
        dueDate: null,
        accountDate: null,
        products: [],
        deposit: { createDeposit: false },
        sendType: "post",
        autoSendType: "print",
        addAttachment: false,
        userCreatedAttachment: true,
        pdfEditorContent: "",
        base64Pdf: {
          base64FileString: "",
          fileName: "",
        },
        freeText: "Vakuuslasku sopimuksen mukaisesti.",
        vatFreeText: "",
        sendToApartmentAddress: true,
        sendingAddress: {
          addExtraLine: false,
          addressLine2: null,
          address: null,
          zipCode: null,
          city: null,
          country: null,
        },
        evoice: {
          evoiceOperator: "",
          evoiceId: "",
          evoiceAddress: "",
        },
        outgoingPayments: { inUse: false, subtractDate: "" },
        costCentre: "",
      },
      maxRefundAmount: 0,
      vatTypes: globalValues.vatTypes,
      productItemTypes: globalValues.productItemTypes,
      dueDates: globalValues.dueDates,

      validations,
      invoiceHelpers,
    };
  },

  computed: {
    ...mapState("product", ["products"]),
    ...mapState("account", ["currentUser"]),
    ...mapState("invoice", ["loading", "saving"]),
    ...mapState("deposit", ["currentDeposit"]),

    serviceAccounts() {
      return this.getServiceAccounts();
    },

    invoiceReceivers() {
      return invoiceHelpers.getInvoiceReceivers(null, this.currentDeposit);
    },

    isCompany() {
      return this.currentUser.currentAccount.isCompany;
    },

    isRefundForm() {
      return this.$route.path.includes("/refund/");
    },

    sendTypes() {
      return globalValues.sendTypes(this.principalTenant.tenantId.isCompany, this.isCompany);
    },

    paymentTerms() {
      return globalValues.paymentTerms(this.invoice.invoiceInterval);
    },

    totalSumWithVat() {
      let sum = 0;
      this.invoice.products.forEach((el) => {
        sum += el.amount * el.count * (1 + el.taxpr / 100);
      });
      return sum;
    },

    validateMaxRefundAmount() {
      if (this.isRefundForm) {
        return this.roundDecimals(this.maxRefundAmount + this.totalSumWithVat) >= 0;
      } else {
        return true;
      }
    },
  },

  watch: {
    currentDeposit: function (val) {
      // Set principal tenant
      this.principalTenant = invoiceHelpers.getPrincipalTenant(null, val);
      // Mark invoice as deposit invoice
      this.invoice.isDepositInvoice = true;
      this.getDepositProducts();
      this.setSendingAddress();
      this.updateReceiverInfo(this.principalTenant);
      this.setLoading(false);
    },

    inv: async function (val) {
      this.invoice = _.cloneDeep(val) || {};

      // Set principal tenant
      const tenant = invoiceHelpers.getInvoiceTenantByClientId(
        this.invoice.contract,
        this.invoice.tenant.clientId
      );

      this.principalTenant = tenant;

      // Get products
      this.getDepositProducts();

      if (this.$route.name == "Luo hyvityslasku") {
        // Get evoice info from tenant, not from original invoice
        this.getTenantEvoiceInformation(true);
        // set orig invoice deposits to data array
        this.setAttachedDeposits(val.attachedDeposits);
        // Set max refund sum (this.invoice is refundable invoice)
        this.setMaxRefundAmount(this.invoice);

        // Set products
        this.invoice.products = this.invoice.products.map((el) => {
          el.count = el.count * -1;
          return el;
        });

        this.invoice.freeText = "Hyvityslasku sopimuksen mukaan.";
      } else if (this.$route.name == "Muokkaa kohdistettua hyvityslaskua") {
        try {
          const { originalInvoice } = await this.getAttachedDepositsAndOriginalInvoice(val);
          this.setMaxRefundAmount(originalInvoice);
        } catch (err) {
          this.showPopup(err, "error");
        }
      }

      // Language
      if (this.canSetInvoiceLanguage({ ropoCapital: false, talenom: true }, this.principalTenant)) {
        this.invoice.language = this.principalTenant.tenantId.invoiceLanguage;
      }

      // Outgoing payments
      this.invoice.outgoingPayments.inUse = !this.currentUser.currentAccount.settings
        .outgoingPayments
        ? false
        : this.invoice.outgoingPayments.inUse;

      if (this.invoice.outgoingPayments.subtractDate) {
        this.invoice.outgoingPayments.subtractDate = new Date(
          this.invoice.outgoingPayments.subtractDate
        )
          .toISOString()
          .substring(0, 7);
      }

      this.setLoading(false);

      // this set free text to quill editor if pdf in use
      if (this.invoice.addAttachment) {
        if (this.invoice.userCreatedAttachment && this.invoice.pdfEditorContent) {
          this.showQuillEditor = true;
          this.showUploadPdf = false;
          await this.$nextTick();
          this.$refs.QuillEditor.setContent(this.invoice.pdfEditorContent);
        } else if (
          !this.invoice.userCreatedAttachment &&
          this.invoice.base64Pdf?.base64FileString &&
          this.invoice.base64Pdf?.fileName
        ) {
          this.showUploadPdf = true;
          this.showQuillEditor = false;
          await this.$nextTick();
          this.$refs.uploadPdf.setContent(this.invoice.base64Pdf);
        }
      } else {
        this.invoice.userCreatedAttachment = true;
        this.showQuillEditor = true;
        this.showUploadPdf = false;
      }
    },

    "invoice.invoiceInterval": {
      deep: true,
      handler(newVal) {
        if (newVal == 1 && this.invoice.paymentTerm > 28) {
          this.invoice.paymentTerm = 14;
          this.showPopup("Maksuehtoa muutettu", "info");
        }
      },
    },

    "invoice.billDate"() {
      if (this.isTalenomService) {
        this.invoice.accountDate = this.invoice.billDate || null;
      }
    },

    "invoice.accountDate"() {
      if (this.isTalenomService) {
        this.invoice.accountDate = this.invoice.billDate || null;
      }
    },
  },

  methods: {
    ...mapActions("product", ["getInvoiceProducts"]),
    ...mapMutations("invoice", ["setLoading", "setSaving"]),
    ...mapMutations("deposit", ["setAttachedDeposits"]),

    getDepositProducts() {
      this.getInvoiceProducts(["Vakuus"]);
    },

    handleAddAttachmentChange(val) {
      if (!val && this.$refs.QuillEditor) this.$refs.QuillEditor.deleteContent();
      if (!val && this.$refs.uploadPdf) this.$refs.uploadPdf.deleteContent();
    },

    handleUserCreatedAttachmentChange(val) {
      if (val) {
        if (this.$refs.uploadPdf) this.$refs.uploadPdf.deleteContent();
        this.showUploadPdf = false;
        this.showQuillEditor = true;
      } else {
        if (this.$refs.QuillEditor) this.$refs.QuillEditor.deleteContent();
        this.showQuillEditor = false;
        this.showUploadPdf = true;
      }
    },

    handleSendTypeChange(type) {
      if (type === "evoice" && this.invoice.isRecurrent && this.invoice.autoSendType !== "evoice") {
        this.invoice.autoSendType = "evoice";
        this.showPopup("Toistuvan laskun lähetystapa vaihdettu verkkolaskuksi.", "info");
      }
    },

    setMaxRefundAmount(invoice) {
      if (invoice) {
        const openAmount = invoice.openAmount || 0;
        const totalAmount = invoice.totalAmount;

        if (openAmount === 0) {
          this.maxRefundAmount = 0;
          return;
        }

        // Calc products amount
        const productsAmount = invoiceHelpers.calcProductsTotalAmount(invoice);
        // Calc interests
        const interests = totalAmount - productsAmount;
        // Calculate max refund sum
        this.maxRefundAmount = openAmount - interests;
      } else {
        this.maxRefundAmount = Number.MAX_VALUE;
      }
    },

    updateReceiverInfo(tenant) {
      if (tenant) {
        this.invoice.tenant.name = tenant.tenantId?.name || "";
        this.invoice.tenant.email = tenant.tenantId?.email || "";

        if (!tenant.tenantId.isCompany) {
          this.invoice.evoice.evoiceOperator = "";
          this.invoice.evoice.evoiceId = "";
          this.invoice.evoice.evoiceAddress = "";
          if (this.invoice.sendType === "evoice") {
            this.invoice.sendType = "print";
            this.invoice.autoSendType = "print";
            this.showPopup(
              "Verkkolasku vaihdettu itsetulostukseksi. Vastaanottaja ei ole yritys.",
              "info"
            );
          }
        }
      }
    },

    updateInvoiceLanguage() {
      if (this.canSetInvoiceLanguage({ ropoCapital: true, talenom: true }, this.principalTenant)) {
        const currentLanguage = this.invoice.language;
        this.invoice.language = this.principalTenant.tenantId.invoiceLanguage;
        if (currentLanguage !== this.invoice.language)
          this.showPopup("Huomaa muuttunut laskun kieli", "info");
      }
    },

    getTenantEvoiceInformation(checkIfEvoiceInUse) {
      if (checkIfEvoiceInUse && this.invoice.sendType !== "evoice") return;

      if (checkIfEvoiceInUse) {
        if (
          this.isRefundForm &&
          this.principalTenant.tenantId.evoice.evoiceOperator &&
          this.principalTenant.tenantId.evoice.evoiceId &&
          this.principalTenant.tenantId.evoice.evoiceAddress &&
          (this.invoice.evoice.evoiceOperator !==
            this.principalTenant.tenantId.evoice.evoiceOperator ||
            this.invoice.evoice.evoiceId !== this.principalTenant.tenantId.evoice.evoiceId ||
            this.invoice.evoice.evoiceAddress !==
              this.principalTenant.tenantId.evoice.evoiceAddress)
        ) {
          this.invoice.evoice.evoiceOperator = this.principalTenant.tenantId.evoice.evoiceOperator;
          this.invoice.evoice.evoiceId = this.principalTenant.tenantId.evoice.evoiceId;
          this.invoice.evoice.evoiceAddress = this.principalTenant.tenantId.evoice.evoiceAddress;

          this.showPopup(
            "Verkkolaskutiedot poikkeavat toisistaan hyvitettävällä laskulla ja vuokralaisen tiedoissa. Tiedoiksi päivitettiin vuokralaisen verkkolaskuosoitteet.",
            "info"
          );
        }
      } else {
        this.invoice.evoice.evoiceOperator = this.principalTenant.tenantId.evoice.evoiceOperator;
        this.invoice.evoice.evoiceId = this.principalTenant.tenantId.evoice.evoiceId;
        this.invoice.evoice.evoiceAddress = this.principalTenant.tenantId.evoice.evoiceAddress;
      }
    },

    setSendingAddress() {
      if (this.invoice.sendToApartmentAddress) {
        let apartment = null;
        if (this.currentDeposit.relatedContract) {
          apartment = this.currentDeposit.relatedContract.apartment.id;
        } else {
          apartment = this.currentDeposit.relatedApartment;
        }

        // Remove extraline
        this.invoice.sendingAddress.addExtraLine = false;
        this.invoice.sendingAddress.addressLine2 = null;
        const apartmentNumber = apartment.apartmentNumber ? apartment.apartmentNumber : "";
        this.invoice.sendingAddress.address = `${apartment.address} ${apartmentNumber}`;
        this.invoice.sendingAddress.zipCode = apartment.zipCode;
        this.invoice.sendingAddress.city = apartment.city;
        this.invoice.sendingAddress.country = apartment.country || null;
      } else {
        console.log(this.principalTenant);
        this.invoice.sendingAddress.address = this.principalTenant.tenantId.address;
        this.invoice.sendingAddress.zipCode = this.principalTenant.tenantId.zipCode;
        this.invoice.sendingAddress.city = this.principalTenant.tenantId.city;
        this.invoice.sendingAddress.country = this.principalTenant.tenantId.country || null;
      }
    },

    checkVatFreeProducts() {
      let boolean = false;
      this.invoice.products.forEach((el) => {
        if (el.taxpr === 0) boolean = true;
      });
      return boolean;
    },

    allowedBillDates(val) {
      const today = new Date(moment(new Date()).format("YYYY-MM-DD"));
      return new Date(val).getTime() >= today ? true : false;
    },

    allowedAccountDates(val) {
      const billDateTime = this.invoice.billDate
        ? new Date(moment(this.invoice.billDate).format("YYYY-MM-DD")).getTime()
        : null;
      const dueDateTime = this.invoice.dueDate
        ? new Date(moment(this.invoice.dueDate).format("YYYY-MM-DD")).getTime()
        : null;
      const todayTime = new Date(val).getTime();

      if (billDateTime) {
        if (dueDateTime) {
          return todayTime >= billDateTime && todayTime < dueDateTime ? true : false;
        } else {
          return todayTime >= billDateTime ? true : false;
        }
      } else {
        return this.allowedBillDates(val);
      }
    },

    allowedDueDates(val) {
      const today = new Date(moment(new Date()).format("YYYY-MM-DD"));
      const show = new Date(val).getTime() > today ? true : false;
      return show;
    },

    allowedMonths(val) {
      const time = new Date(val).getTime();
      const startOfMonth = new Date(moment().startOf("month")).getTime();
      if (time >= startOfMonth) return true;
      else return false;
    },

    updateDates(field) {
      // Refund form
      if (this.isRefundForm) {
        if (field === "billDate") {
          this.invoice.dueDate = this.invoice.billDate || null;
          this.invoice.accountDate = this.invoice.billDate || null;
          this.showPopup("Eräpäivää ja kirjauspäivää siirretty automaattisesti", "info");
        } else if (field === "dueDate") {
          this.invoice.billDate = this.invoice.dueDate || null;
          this.invoice.accountDate = this.invoice.dueDate || null;
          this.showPopup("Laskun päivää ja kirjauspäivää siirretty automaattisesti", "info");
        }
      } else {
        // Invoice form
        if (field === "billDate" || field === "paymentTerms") {
          // Move dueDate
          if (this.invoice.billDate && this.invoice.firstPaymentTerm) {
            this.invoice.dueDate = moment(this.invoice.billDate)
              .add(this.invoice.firstPaymentTerm, "days")
              .format("YYYY-MM-DD");
          }
          // Change accountDate to null
          if (field === "billDate") this.invoice.accountDate = null;
        } else if (field === "dueDate") {
          // Change paymentTerms
          if (this.invoice.billDate && this.invoice.dueDate) {
            this.invoice.firstPaymentTerm = moment(this.invoice.dueDate).diff(
              moment(this.invoice.billDate),
              "days"
            );
          } else if (!this.invoice.billDate && this.invoice.dueDate) {
            this.invoice.billDate = moment().format("YYYY-MM-DD");
            this.invoice.firstPaymentTerm = moment(this.invoice.dueDate).diff(
              moment(this.invoice.billDate),
              "days"
            );
          }
        }
      }
    },

    addProduct() {
      this.invoice.products.push({
        desc: "",
        count: null,
        itemtype: "kpl",
        amount: null,
        taxpr: 0,
        accountingId: null,
        productId: null,
      });
    },

    deleteProduct(index) {
      this.invoice.products.splice(index, 1);
    },

    insertDates() {
      const {
        errorMessage,
        billDate,
        accountDate,
        dueDate,
        setOtherDueDatesDefault,
        otherDueDatesDefault,
      } = invoiceHelpers.getDatesFromContract(this.contr, this.currentUser.currentAccount);

      if (errorMessage) {
        return this.showPopup(errorMessage, "error");
      }

      if (setOtherDueDatesDefault) {
        this.otherDueDatesDefault = otherDueDatesDefault;
      }

      if (billDate && accountDate && dueDate) {
        // Set dates
        this.invoice.billDate = billDate;
        this.invoice.accountDate = accountDate;
        this.invoice.dueDate = dueDate;
      }
    },

    setProduct(event, product, index) {
      if (event) {
        product.productNumber = event.productNumber;
        product.desc = event.desc;
        product.amount = event.amount;
        product.count = this.isRefundForm ? event.count * -1 : event.count;
        product.taxpr = event.taxpr;
        product.itemtype = event.itemtype;
        product.accountingId = event.accountingId;
        product.productId = event._id;
        delete product._id;

        this.invoice.products.splice(index, 1, product);
      }
    },

    async submit() {
      const dueDate = new Date(this.invoice.dueDate).getTime();
      const accountDate = new Date(this.invoice.accountDate).getTime();
      const billDate = new Date(this.invoice.billDate).getTime();
      const now = new Date(moment().format("YYYY-MM-DD")).getTime();

      if (this.$refs.form.validate()) {
        // checkDates in refund
        if (!this.$route.path.includes("refund")) {
          if (billDate < now || accountDate < now || dueDate < now) {
            return this.showPopup(
              "Laskun pvm, kirjauspäivä tai eräpäivä ei voi olla pienempi kuin tämä päivä.",
              "error"
            );
          }
        }

        // check dates
        if (billDate > accountDate || billDate > dueDate) {
          return this.showPopup(
            "Laskun päiväys ei voi olla suurempi kuin kirjauspäivä tai eräpäivä.",
            "error"
          );
        }

        if (accountDate > dueDate) {
          return this.showPopup("Laskun kirjauspäivä ei voi olla suurempi kuin eräpäivä.", "error");
        }

        // check products
        if (this.invoice.products.length == 0) {
          return this.showPopup("Lisää ainakin yksi tuoterivi.", "error");
        }

        // check if total sum si valid
        if (!this.isRefundForm && this.totalSumWithVat <= 0) {
          return this.showPopup(
            "Hyvitykset ovat liian suuret. Laskun loppusumman tulee olla positiivinen.",
            "error"
          );
        }

        // check refund amount
        if (this.isRefundForm && !this.validateMaxRefundAmount) {
          return this.showPopup(
            `Voit hyvittää laskua vain jäljellä olevan pääoman verran (ilman koron osuutta). Maksimihyvitys on ${this.formatCurrency(
              this.maxRefundAmount
            )}.`,
            "error"
          );
        }

        // add set saving variable to true
        this.setSaving(true);

        // Get pdf content
        if (this.invoice.addAttachment) {
          if (this.showUploadPdf && this.$refs.uploadPdf && !this.invoice.userCreatedAttachment) {
            const obj = this.$refs.uploadPdf.getContent();
            this.invoice.base64Pdf.fileName = obj.fileName;
            this.invoice.base64Pdf.base64FileString = obj.base64FileString;
          } else if (
            this.showQuillEditor &&
            this.$refs.QuillEditor &&
            this.invoice.userCreatedAttachment
          ) {
            this.invoice.pdfEditorContent = this.$refs.QuillEditor.getContent();
          }
        }

        // Check where to send
        if (this.isRefundForm) {
          this.$emit("sendrefundinvoice", {
            invoice: this.invoice,
            principalTenant: this.principalTenant,
          });
        } else {
          if (this.invoice.sendStatus === "pending") {
            if (
              confirm(
                "Olet muokkaamassa lähetystä odottavaa laskua. Lasku tallennetaan luonnoksiin ja joudut lähettämään sen uudelleen."
              )
            ) {
              this.$emit("sendinvoice", {
                invoice: this.invoice,
                principalTenant: this.principalTenant,
              });
            }
          } else {
            this.$emit("sendinvoice", {
              invoice: this.invoice,
              principalTenant: this.principalTenant,
            });
          }
        }
      } else {
        this.showPopup("Jotkin kentät ovat puutteellisesti täydennetty", "error");
      }
    },
  },
};
</script>

<style scoped>
p {
  line-height: 17px !important;
  margin-bottom: 6px !important;
  font-size: 15px;
}

.details-container {
  display: flex;
}

.contract-text {
  font-size: 14px;
  font-weight: 600;
  color: rgb(51, 51, 51);
}

.invoice-overview {
  padding: 20px;
  width: 100%;
  border: 2px solid var(--v-success-base);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}

.invoice-overview p {
  padding: 0;
  margin: 0;
}

.tooltip-box {
  background-color: rgb(131, 131, 131);
  color: white;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.v-tooltip__content {
  font-size: 14px !important;
  opacity: 0.9 !important;
  background-color: rgb(78, 78, 78);
  padding: 25px;
}
</style>
